const mqSmall = window.matchMedia('(max-width: 640px)');
const mqMedium = window.matchMedia('(min-width: 641px)');

// Funzioni al caricamento e al resize
// ===================================
window.addEventListener('load', onLoadFunction);
window.addEventListener('resize', onResizeFunction);

function onLoadFunction() {
    onResizeFunction();
}

var resizeTimer;

function onResizeFunction() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
        avviaWatcher();

    }, 100);
}

// Reveal stagger
// ==============
import scrollMonitor from 'scrollmonitor';

function avviaWatcher() {
    var myElement = document.querySelector(".metodobox");
    if (myElement != null) {
        var elementWatcher = scrollMonitor.create(myElement, -250);

        elementWatcher.enterViewport(function () {
            rivelaMetodi(mqMedium);
        });
    }
}

function rivelaMetodi(mq) {
    let fase = document.querySelectorAll('.metodobox__fase');

    // Se sono su tablet o desktop
    if (fase != null && mq.matches) {
        for (let f = 0; f < fase.length; f++) {
            const element = fase[f];
            setTimeout(() => {
                element.classList.add('metodobox__fase_entered');
            }, 250 * f);
        }
    }
    if (fase != null && !mq.matches) {
        fase.forEach(element => {
            element.classList.remove('metodobox__fase_entered');
        });
    }
}

