import Swiper from 'swiper';
import SwiperCore, { Navigation, Autoplay } from 'swiper/core';

SwiperCore.use([Navigation, Autoplay]);

const swiper = new Swiper('.swiper-container', {
    autoplay: {
        delay: 5000,
    },
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,

    navigation: {
        prevEl: '.clientShowcase__arrow_left',
        nextEl: '.clientShowcase__arrow_right',
    },
});